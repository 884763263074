<template>
	<v-dialog :value="toDelete" max-width="500" persistent>
		<v-card :loading="loading">
			<v-card-title class="text-h5">Are you sure you want to delete this {{ model }}?</v-card-title>
			<v-card-actions>
				<v-spacer />
				<v-btn color="error darken-1" text @click="closeDelete">Cancel</v-btn>
				<v-btn color="primary darken-1" text @click="deleteItemConfirm">OK</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Snack from "@/mixins/Snack.js";

export default {
	name: "DeleteDialog",
	mixins: [Snack],
	props: {
		model: {type: String, required: true}
	},
	data: () => ({ loading: false }),
	computed: {
		...mapGetters({
			toDelete: "toDelete/item"
		})
	},
	methods: {
		...mapMutations("toDelete", ["SET_ITEM_TO_DELETE"]),
		async deleteItemConfirm () {
			this.loading = true
			try {
				await this.$axios.delete(this.$util.format(
					this.$urls[this.model].detail,
					this.toDelete.id
				))
				this.closeDelete()
				await this.openSnack({text: `${this.model} delete successfully.`, color: "success"})
				this.$emit("init")
			} catch (e) {
				const status = e.response.status
				if (status < 500 && status >= 400) {
					await this.openSnack({
						text: e.response.data.detail
					})
				} else {
					await this.openSnack({
						text: `${this.model} delete failed. Please try again.`
					})
				}
			}
			this.loading = false
		},
		closeDelete () {
			this.SET_ITEM_TO_DELETE(null)
		},
	}
}
</script>

<style scoped>

</style>
